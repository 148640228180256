<template>
    <LiefengContent>
        <template v-slot:title>趣游逢源效果统计</template>
        <template v-slot:contentArea>
            
            <Card class="protarit-div">
                <div class="portrait-title">{{ "做题前评分情况" }}</div>
                <div class="pie-image">
                    <div class="pie-content">
                        <div class="image">
                            <VeHistogram :extend="hisExtend" :data="beforeData"></VeHistogram>
                        </div>
                        <div class="table">
                            <Table stripe :columns="beforeTableColumn" :data="beforeTableData"></Table>
                        </div>
                    </div>
                </div>
            </Card>
            <!--用户画像-->
            <Card class="protarit-div">
                <div class="portrait-title">{{ "做题后评分情况" }}</div>
                <div class="pie-image">
                    <div class="pie-content">
                        <div class="image">
                            <VeHistogram :extend="hisExtend" :data="afterData"></VeHistogram>
                        </div>
                        <div class="table">
                            <Table stripe :columns="afterTableColumn" :data="afterTableData"></Table>
                        </div>
                    </div>
                </div>
            </Card>
            <!-- <Card class="protarit-div">
                <div class="portrait-title">{{ "答题情况" }}</div>
                <div class="pie-image">
                    <div class="pie-content">
                        <div class="image">
                            <VeHistogram :extend="hisExtend" :data="questionData"></VeHistogram>
                        </div>
                        <div class="table">
                            <Table stripe :columns="questionTableColumn" :data="questionTableData"></Table>
                        </div>
                    </div>
                </div>
            </Card> -->
        </template>
    </LiefengContent>
</template>

<script>
// VeLine:折线图  VePie:饼图  VeHistogram:柱状图
import LiefengContent from "@/components/LiefengContent3"
import VeHistogram from "v-charts/lib/histogram.common"
export default {
    components: {
        LiefengContent,
        VeHistogram,
    },
    data() {
        return {
            // 答题前
            afterTableColumn: [
                { title: "分值", key: "key", align: "center" },
                { title: "人次", key: "value", align: "center" },
            ],
            afterTableData: [],
            afterData: {
                columns: ["分值", "人次"],
                rows: [],
            },
            // 答题后
            beforeTableColumn: [
                { title: "分值", key: "key", align: "center" },
                { title: "人次", key: "value", align: "center" },
            ],
            beforeTableData: [],
            beforeData: {
                columns: ["分值", "人次"],
                rows: [],
            },

            // 答题情况
            questionTableColumn: [
                { title: "题目", key: "ext", align: "center" },
                { title: "正常人次", key: "num", align: "center" },
                { title: "错误人次", key: "num", align: "center" },
            ],
            questionTableData: [],
            questionData: {
                columns: ["题目", "正确人次", "错误人次"],
                rows: [],
            },
            // 柱状图的样式
            hisExtend: {
                series: {
                    barMaxWidth: 50,
                },
            },
        }
    },
    methods: {
        // 获取机构引入社区累计用户数对比
        getStatIntroduceByDate() {
            this.$get("/gateway/api/syscenic/pc/question/answerResultStatistics", {
                topicId: this.$route.query.topicId || "2",
            }).then(res => {
                this.afterTableData = []
                this.beforeTableData = []
                this.questionTableData = []
                // 做题前评分
                if (res.data && res.data.scoreAfter && res.data.scoreAfter.length) {
                    let total = 0
                    this.afterTableData = res.data.scoreAfter.map(item => {
                        total = total + item.value
                        return {
                            ...item,
                            key: item.key + "分",
                        }
                    })
                    this.afterData.rows = res.data.scoreAfter.map(item => {
                        return {
                            '分值': item.key + '分',
                            '人次': item.value,
                        }
                    })
                    this.afterTableData.push({
                        key:'合计',
                        value:total
                    })
                }
                // 做题后评分
                if (res.data && res.data.scoreBefore && res.data.scoreBefore.length) {
                    let totals = 0
                    this.beforeTableData = res.data.scoreBefore.map(item => {
                        totals = totals + item.value
                        return {
                            ...item,
                            key: item.key + "分",
                        }
                    })
                    this.beforeData.rows = res.data.scoreBefore.map(item => {
                        return {
                            '分值': item.key + '分',
                            '人次': item.value,
                        }
                    })
                    this.beforeTableData.push({
                        key:'合计',
                        value:totals
                    })
                    
                }
                // 答题情况
                if(res.data && res.data.questionRecord && res.data.questionRecord.length){
                    this.questionTableData = res.data.questionRecord.map(item =>{
                        return{
                            ...item,

                        }
                    })
                }
            })
        },
    },
    async created() {
        await this.getStatIntroduceByDate()

        this.$core.urlToBase64('https://liefeng.oss-cn-shenzhen.aliyuncs.com/TEST/zjsm/20230804/63170bdb-2f2d-043e-66c1-6d9413f53f54.png').then(res=>{
            console.log('res',res);
        })
    },
}
</script>

<style lang="less" scoped>
.title-div {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .title-left {
        width: 80%;
        display: flex;
        flex-flow: wrap;
        .item-num {
            width: 150px;
            height: 150px;
            margin-right: 10px;
            text-align: center;
            .num {
                padding-top: 50px;
                padding-bottom: 40px;
                font-size: 30px;
                font-weight: bold;
            }
            .title {
                font-size: 16px;
            }
        }
    }
    .title-center {
        width: 10%;
        .item-num {
            margin: 0 auto;
            width: 150px;
            height: 150px;
            margin-right: 10px;
            text-align: center;
            .num-red {
                font-size: 30px;
                padding-top: 50px;
                padding-bottom: 40px;
                font-weight: bold;
                color: red;
            }
            .title {
                font-size: 16px;
            }
        }
    }
    .title-right {
        width: 10%;
        .item-num {
            margin: 0 auto;
            width: 150px;
            height: 150px;
            margin-right: 10px;
            text-align: center;
            .num-weight {
                padding-top: 50px;
                padding-bottom: 40px;
                font-weight: bold;
                font-size: 40px;
            }
            .title {
                font-size: 16px;
            }
        }
    }
}
.protarit-div {
    width: 100%;
    margin-top: 20px;
    .portrait-title {
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        // border-bottom: 1px solid #ccc;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    .pie-image {
        // border-bottom: 1px solid #ccc;
        font-size: 16px;
        .title-padding {
            padding: 10px 0;
            // font-weight: bold;
            font-size: 16px;
            color: #0fb26c;
            text-decoration: underline;
            padding-left: 60%;
            font-size: 24px;
        }
        .pie-content {
            display: flex;
            justify-content: space-between;
            .image {
                width: 60%;
            }
            .table {
                width: 40%;
            }
        }
    }
    .people-num {
        .title-padding {
            font-weight: bold;
            font-size: 16px;
            padding: 10px 0;
        }
    }
    .link-div {
        width: 100%;
        height: 400px;
        padding: 10px 0;
        .select-div {
            display: flex;
            justify-content: flex-start;
        }
    }
    .protrait-table {
        padding: 10px 0;
    }
    // 饼图css
    .jus-div {
        width: 100%;
    }
}
.content {
    margin-top: 120px;
    .link-div {
        .select-div {
            display: flex;
            justify-content: flex-start;
        }
    }
}
.table-div {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .table-left,
    .table-right {
        width: 49.5%;
        min-height: 699px;
        overflow: scroll;
        .update-time {
            margin-bottom: 5px;
            font-size: 16px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            .table-title {
                padding-left: 53px;
                font-weight: bold;
            }
            .table-btn {
                color: #169bd5;
                cursor: pointer;
                user-select: none;
            }
        }
    }
}
</style>